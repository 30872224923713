import React from 'react';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/styles';
import {
  Paper, FormControl, InputLabel, Input, Button, Box,
} from '@material-ui/core';
import { observable } from 'mobx';

const styles: any = (theme: any) => ({
  login: {
    top: '50%',
    left: '50%',
    position: 'absolute' as 'absolute',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(3),
  },
});

@inject('store')
@observer
class Login extends React.Component<any> {
  @observable
  email: string = '';

  @observable
  password: string = '';

  @observable
  disabled: boolean = true;

  @observable
  sending: boolean = false;

  auth: any;

  constructor(props: any) {
    super(props);
    this.auth = props.store.auth;
    this.resetForm();
  }

  private validate = () => {
    this.disabled = !(this.email && this.password);
    return this.disabled;
  };

  private resetForm = () => {
    this.email = '';
    this.password = '';
    this.validate();
  };

  private changeEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.email = event.target.value as string;
    this.validate();
  };

  private changePassword = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.password = event.target.value as string;
    this.validate();
  };

  private login = async () => {
    if (this.disabled) {
      return;
    }
    const { email, password } = this;
    this.sending = true;
    try {
      await this.auth.login({ username: email, password });
      this.resetForm();
    } catch (e) {
      this.password = '';
      this.validate();
    } finally {
      this.sending = false;
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <Paper className={classes.login}>
        <Box my={2}>
          <FormControl fullWidth>
            <InputLabel>Email or username</InputLabel>
            <Input
              value={this.email}
              onChange={this.changeEmail}
              disabled={this.sending}
            />
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel>Password</InputLabel>
            <Input
              type="password"
              value={this.password}
              onChange={this.changePassword}
              disabled={this.sending}
            />
          </FormControl>
        </Box>
        <Box mb={2}>
          <Button
            fullWidth
            disabled={this.disabled || this.sending}
            onClick={this.login}
            size="large"
            variant="contained"
            color="primary"
          >
            Login
          </Button>
        </Box>
      </Paper>
    );
  }
}

export default withStyles(styles)(Login);
