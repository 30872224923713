import React from 'react';
import { observer, inject } from 'mobx-react';

import { Modal, Paper, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { Close } from '@material-ui/icons';

const styles: any = (theme: any) => ({
  modal: {
    top: '50%',
    left: '50%',
    position: 'absolute' as 'absolute',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(3),
  },
  close: {
    position: 'absolute' as 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
});

@inject('store')
@observer
class SModal extends React.Component<any> {
  modal: any;

  constructor(props: any) {
    super(props);
    this.modal = props.store.modal;
  }

  private closeModal = () => {
    this.modal.close();
  };

  render() {
    const { classes } = this.props;
    const open = this.modal.state;
    const Component = this.modal.component as any;
    const { props } = this.modal;

    if (!open || !Component) {
      return null;
    }

    return (
      <Modal open={open} onClose={this.closeModal} >
        <Paper className={classes.modal}>
          <IconButton size="small" className={classes.close} onClick={this.closeModal} >
            <Close />
          </IconButton>
          <Component {...props} />
        </Paper>
      </Modal>
    );
  }
}

export default withStyles(styles)(SModal);
