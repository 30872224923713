import React from 'react';
import { inject, observer } from 'mobx-react';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

@inject('store')
@observer
class SSnackbar extends React.Component {
  snackbarStore: any;

  constructor(props: any) {
    super(props);
    this.snackbarStore = props.store.snackbar;
  }

  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={this.snackbarStore.open}
        autoHideDuration={6000}
        onClose={this.snackbarStore.handleClose}
        onExited={this.snackbarStore.handleExited}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={this.snackbarStore.handleClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      >
        <Alert onClose={this.snackbarStore.handleClose} severity={this.snackbarStore.severity || 'success'}>
          {this.snackbarStore.message}
        </Alert>
      </Snackbar>
    );
  }
}

export default SSnackbar;
