import React from 'react';
import { Flag, Image, LibraryBooks } from '@material-ui/icons';
import ReportList from '../../components/post/report-list';
import MediaList from '../../components/post/media-list';

import { IRouteItem } from '../interfaces';

const items: IRouteItem[] = [
  {
    id: 'Posts',
    path: '/posts',
    icon: <LibraryBooks />,
    items: [
      {
        id: 'Reported posts', icon: <Flag />, path: '/reports', component: ReportList,
      },
      {
        id: 'Moderation media posts', icon: <Image />, path: '/media', component: MediaList,
      },
    ],
  },
];

export default items;
