import React from 'react';
import clsx from 'clsx';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import {
  Typography, IconButton, CardContent, Divider, Collapse, Button, Box, TablePagination, Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { ExpandMore } from '@material-ui/icons';

import Post from '../post';
import Report from './report';

import confirmModal from '../../utils/confirm-modal';
import { IPostReports } from '../../../utils/dto/post';

const styles: any = (theme: any) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  pager: {
    minHeight: 52,
    display: 'flex',
    alignItems: 'center',
  },
});

const rowsPerPage = 10;

@inject('store')
@observer
class ReportPost extends React.Component<{ store?: any, classes?: any, post: IPostReports }> {
  posts: any;

  @observable
  expanded: boolean = false;

  @observable
  page: number = 0;

  constructor(props: any) {
    super(props);
    this.posts = props.store.post;
  }

  private toggleExpanded = () => {
    this.expanded = !this.expanded;
  };

  private ban = async () => {
    const { post } = this.props;
    this.posts.setReport(post);
    await this.posts.ban();
    await this.posts.getReportList();
  };

  private unban = async () => {
    const { post } = this.props;
    this.posts.setReport(post);
    await this.posts.unban();
    await this.posts.getReportList();
  };

  private getActionButton = () => {
    const { post } = this.props;
    const banned = post.status === 'BANNED';
    return (
      <>
        {post.status === 'PENDING' && (
          <Box ml={2}>
            <Button
              variant={banned ? 'outlined' : 'contained'}
              color="primary"
              size="small"
              onClick={() => confirmModal(this.unban)}
            >
              Publish
            </Button>
          </Box>
        )}
        <Box ml={2} >
          <Button
            variant={banned ? 'outlined' : 'contained'}
            color={banned ? 'primary' : 'secondary'}
            size="small"
            onClick={() => confirmModal(banned ? this.unban : this.ban)}
          >
            {banned ? 'Unban' : 'Ban'}
          </Button>
        </Box>
      </>
    );
  };

  private handleChangePage = (event: unknown, newPage: number) => {
    this.page = newPage;
  };

  private getPaged = (data: any[]) => {
    const { page } = this;
    return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };


  render() {
    const { classes, post } = this.props;
    const { reports: all } = post;
    const [report, ...reports] = all;
    const paged = this.getPaged(reports);
    return (
      <Post
        post={post}
        expanded={this.expanded}
        actions={(
          <>
            <Button size="small" color="primary" onClick={this.toggleExpanded}>
              Total reports:
              {' '}
              {all.length}
            </Button>
            {this.getActionButton()}
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: this.expanded,
              })}
              onClick={this.toggleExpanded}
              aria-expanded={this.expanded}
              aria-label="show more"
            >
              <ExpandMore />
            </IconButton>
          </>
        )}
      >
        <CardContent>
          <Typography variant="h6" color="textSecondary" component="p">
            Latest report:
          </Typography>
          <Report report={report} />
          {reports.length > 0
            && (
              <Collapse in={this.expanded} timeout="auto" unmountOnExit>
                <Grid container spacing={0}>
                  <Grid item xs={6}>
                    <div className={classes.pager}>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        component="p"
                      >
                        Other reports:
                      </Typography>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <TablePagination
                      component="div"
                      count={reports.length}
                      rowsPerPageOptions={[]}
                      rowsPerPage={rowsPerPage}
                      page={this.page}
                      backIconButtonProps={{ 'aria-label': 'previous page' }}
                      nextIconButtonProps={{ 'aria-label': 'next page' }}
                      onChangePage={this.handleChangePage}
                    />
                  </Grid>
                </Grid>
                {paged.map((item, i: number) => (
                  <>
                    <Box mt={i !== 0 ? 2 : 0}>
                      <Divider />
                    </Box>
                    <Report report={item} />
                  </>
                ))}
              </Collapse>
            )
          }
        </CardContent>
        <Divider />
      </Post>
    );
  }
}

export default withStyles(styles)(ReportPost);
