import React from 'react';
import { inject, observer } from 'mobx-react';
import { LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const styles = {
  progressbar: {
    zIndex: 9999,
    marginBottom: -4,
  },
};

@inject('store')
@observer
class Progressbar extends React.Component<any> {
  progress: any;

  constructor(props: any) {
    super(props);
    this.progress = props.store.progress;
  }

  render() {
    const { classes } = this.props;
    if (this.progress.completed === null) {
      return null;
    }
    return (<LinearProgress className={classes.progressbar} variant="determinate" color="secondary" value={this.progress.completed} />
    );
  }
}

export default withStyles(styles)(Progressbar);
