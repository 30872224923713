import { observable, action } from 'mobx';

const def = 'Admin Panel';

class MenuStore {
  @observable
  active: string = '/';

  @observable
  state: boolean = true;

  @observable
  title: string = def;

  @action('set active')
  setActive(active: string, title?: string) {
    this.active = active;
    this.title = title || def;
  }

  @action('set state')
  setState(state: boolean) {
    this.state = state;
  }

  @action('set state')
  toggleState() {
    this.state = !this.state;
  }
}

export default new MenuStore();
