import React from 'react';
import {
  Collapse, List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { observable } from 'mobx';
import { withStyles } from '@material-ui/styles';
import { ExpandMore } from '@material-ui/icons';
import clsx from 'clsx';

import { IRouteItem } from '../../routes/interfaces';

const styles: any = (theme: any) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});

@inject('store')
@observer
export default class MenuList extends React.Component<any> {
  render() {
    const {
      items, parent, expanded = true, openParent, ...props
    } = this.props as { items: IRouteItem[], parent: any, expanded: any, openParent: any };
    return (
      <Collapse in={expanded} timeout="auto">
        <List {...props} component="div" disablePadding>
          {items
            .filter(({ menu }) => menu !== false)
            .map(item => (<StyledMenuItem item={item} parent={parent} openParent={openParent} />))}
        </List>
      </Collapse>
    );
  }
}


@inject('store')
@observer
class MenuItem extends React.Component<any> {
  menu: any = {};

  @observable
  expanded: boolean = false;

  path: string = '/';

  constructor(props: any) {
    super(props);
    this.menu = props.store.menu;

    const { item: { path }, parent = {} } = props;
    const { path: parentPath = '' } = parent;
    this.path = `/${parentPath}${path}`.replace(/\/{2,}/g, '/').replace(/\/$/g, '');
    this.openParent();
  }

  private open = (value: boolean = true) => {
    if (this.expanded !== value) {
      this.expanded = value;
    }
  };

  private isActive = () => {
    const { item: { component } } = this.props;
    const { active } = this.menu;

    return component && this.path === active;
  };

  private openParent = () => {
    const { openParent } = this.props;
    const isActive = this.isActive();
    if (openParent && isActive) {
      openParent(true);
    }
  };

  render() {
    const { item = {}, parent, classes } = this.props;

    const {
      id, icon, items: children, component,
    } = item;

    const isActive = this.isActive();
    const hasChildren = children && children.length;

    return (
      <>
        <ListItem
          key={id}
          button
          selected={isActive}
          component={component ? Link : 'li'}
          to={component ? this.path : undefined}
          onClick={() => this.open(!this.expanded)}
          className={parent ? classes.nested : undefined}
        >
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>
            {id}
          </ListItemText>
          {hasChildren
            && (
              <ExpandMore
                className={clsx(classes.expand, { [classes.expandOpen]: this.expanded })}
              />
            )
          }
        </ListItem>
        {hasChildren
          && (
            <MenuList
              items={children}
              parent={item}
              expanded={this.expanded}
              openParent={this.open}
            />
          )}
      </>
    );
  }
}

const StyledMenuItem = withStyles(styles)(MenuItem);
