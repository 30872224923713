import moment from 'moment';
import { dateFormat, dateTimeFormat } from '../../constants';

export interface IRawAdmin {
  _id: string;
  username: string;
  email: string;
  status: string;
  createdAt: string;
  lastActiveAt: string | null;
  requestedResetPassword?: boolean;
}

export interface IAdmin {
  id: string;
  username: string;
  email: string;
  status: string;
  createdAt: string;
  lastActiveAt: string | null;
  requestedResetPassword: boolean;
}

export const admin = ({
  _id, username, email, status, createdAt, lastActiveAt, requestedResetPassword,
}: IRawAdmin): IAdmin => ({
  id: _id,
  username,
  email,
  status,
  createdAt: moment(createdAt).format(dateFormat),
  lastActiveAt: lastActiveAt ? moment(lastActiveAt).format(dateTimeFormat) : 'n/a',
  requestedResetPassword: requestedResetPassword || false,
});
