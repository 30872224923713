export const sites = [
  { value: 13451, name: 'Northwell' },
  { value: 13452, name: 'CHOP' },
  { value: 13453, name: 'Cincinnati Children’s' },
  { value: 13454, name: 'Connecticut Children’s' },
  { value: 13455, name: 'St.Chris' },
];

export const otpTooltip = 'They will use this code to register account and create user '
  + 'name and unique password. If at anytime the user needs to reset password,'
  + ' admin may generate a new 1 time activation code.';

export const dateFormat = 'M/DD/YY';
export const dateTimeFormat = 'M/DD/YY h:mm A';

export const roles = [
  'ADMIN',
];

export const statuses = [
  'ACTIVE',
  'INACTIVE',
  'REMOVED',
];
