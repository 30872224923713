import React from 'react';
import { observer, inject } from 'mobx-react';
import { toJS, observable } from 'mobx';
import {
  TablePagination, Typography, FormControlLabel, Switch, Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
// import {
//   FormControlLabel, Switch, Fab, Chip, Typography,
// } from '@material-ui/core';

import { IPostReports } from '../../../utils/dto/post';

import Post from './post';

const rowsPerPageOptions = [10, 25, 50, 100];

const styles = {
  switcher: {
    minHeight: 52,
  },
};

@inject('store')
@observer
class ReportList extends React.Component<{ classes: any }> {
  posts: any;

  modal: any;

  @observable
  filter: boolean = false;

  constructor(props: any) {
    super(props);
    this.posts = props.store.post;
    this.modal = props.store.modal;

    this.posts.setOrder('desc');
    this.posts.setOrderBy();
    this.posts.setPage(0);
    const [rowsPerPage] = rowsPerPageOptions;
    this.posts.setRowsPerPage(rowsPerPage);
    this.posts.setStatus();
    this.posts.getMediaList(true);
  }


  // eslint-disable-next-line class-methods-use-this
  private getMapped(data: any): IPostReports[] {
    return toJS(data);
  }

  private handleRequestSort = (property: keyof IPostReports) => {
    const { order, orderBy } = this.posts;
    if (orderBy === property && order === 'asc') {
      this.posts.setOrder('desc');
      this.posts.setOrderBy();
      this.posts.getMediaList();
      return;
    }

    const isDesc = orderBy === property && order === 'desc';
    this.posts.setOrder(isDesc ? 'asc' : 'desc');
    this.posts.setOrderBy(property);
    this.posts.getMediaList();
  };

  private handleChangePage = (event: unknown, newPage: number) => {
    this.posts.setPage(newPage);
    this.posts.getMediaList();
  };

  private handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.posts.setRowsPerPage(+event.target.value);
    this.posts.getMediaList();
  };

  private handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.filter = event.target.checked;
    if (this.filter) {
      this.posts.setStatus('PENDING');
    } else {
      this.posts.setStatus();
    }
    this.posts.getMediaList(true);
  };

  render() {
    const {
      /* order, orderBy, */ page, rowsPerPage,
    } = this.posts;
    const { classes } = this.props;
    const paged = this.getMapped(this.posts.list);

    return (
      <div>
        <Typography variant="h5">
          Posts with media (
          {this.posts.total || this.posts.list.length}
          )
        </Typography>
        <Grid container>
          <Grid item xs={6} >
            <FormControlLabel
              className={classes.switcher}
              control={(
                <Switch
                  checked={this.filter}
                  onChange={this.handleChangeFilter}
                />
              )}
              label="Show only PENDING posts"
            />
          </Grid>
          <Grid item xs={6}>
            <TablePagination
              rowsPerPageOptions={
                [...rowsPerPageOptions.filter(i => i < this.posts.total), this.posts.total]
              }
              component="div"
              count={this.posts.total}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'previous page' }}
              nextIconButtonProps={{ 'aria-label': 'next page' }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </Grid>
        </Grid>
        {paged.map((post: IPostReports) => <Post post={post} />)}
      </div>
    );
  }
}
export default withStyles(styles)(ReportList);
