import { EventEmitter } from 'events';

const { localStorage } = window;


class LocalStorage extends EventEmitter {
  public write = write;

  public read = read;

  public remove = write;
}
const ls = new LocalStorage();
export default ls;

export function write(key: string, value: any, expire?: number) {
  localStorage.setItem(key, JSON.stringify({ created: Date.now(), value, expire }));
  ls.emit('ls:write', { key, value, expire });
}

export function read(key: string): any {
  const val = localStorage.getItem(key);
  if (val) {
    try {
      const parsed = JSON.parse(val);
      if (parsed) {
        if (!parsed.expire || parsed.expire + parsed.created >= Date.now()) {
          const { value } = parsed;
          ls.emit('ls:read', { key, value });
          return value;
        }
        remove(key);
      }
    } catch (e) {
      // TODO log later

    }
  }
  return null;
}

export function remove(key: string) {
  localStorage.removeItem(key);
  ls.emit('ls:remove', { key });
}
