import { observable, action } from 'mobx';
import * as api from '../../apis';
import { read, write, remove } from '../../utils/local-storage';
import { roles } from '../../constants';

import snackbarStore from '../snackbar';

class AuthStore {
  @observable
  username: string | null = null;

  @observable
  email: string | null = null;

  @observable
  token: string | null = null;

  constructor() {
    const { token, email, username } = read('token') || {};
    this.username = username;
    this.email = email;
    this.token = token;
  }

  @action('login')
  login = async (data: any) => {
    try {
      const {
        token, email, username, role,
      } = await api.auth.login(data);
      if (!roles.includes(role)) {
        snackbarStore.show('You do not have access', 'error');
        throw new Error('Forbidden');
      }
      this.username = username;
      this.token = token;
      this.email = email;

      write('token', { token, email, username });
    } catch (e) {
      this.logout();
    }
  };

  @action('logout')
  logout = () => {
    this.token = null;
    this.email = null;
    this.username = null;
    remove('token');
  };
}

export default new AuthStore();
