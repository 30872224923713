import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable, toJS } from 'mobx';
import {
  Typography, FormControl, InputLabel, Input, Button,
  InputAdornment, IconButton, Tooltip, Grid, Box,
} from '@material-ui/core';
import { Info, Cached } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';

import { sites, otpTooltip as tooltip } from '../../../constants';
import { otp as getSimpleOTP } from '../../../utils';

@inject('store')
@inject('routing')
@observer
class UserUpdate extends React.Component<any> {
  users: any;

  @observable
  userId: string = '';

  @observable
  siteId: string = '';

  @observable
  otp: string = '';

  @observable
  tooltip: boolean = false;

  @observable
  tooltipFixed: boolean = false;

  @observable
  disabledButton: boolean = true;

  @observable
  disabledUserId: boolean = false;

  constructor(props: any) {
    super(props);
    const { updateCallback } = props;
    if (updateCallback) {
      this.updateCallback = updateCallback;
    }
    this.users = props.store.user;
    this.loadUser();
  }

  private loadUser = async () => {
    const { match = {} } = this.props;
    if (match && match.params && match.params.userId) {
      await this.users.getById(match.params.userId);
    }
    const { userId = null, siteId = null, otp = null } = toJS(this.users.user) || {};
    this.userId = userId;
    this.siteId = siteId;
    this.otp = typeof otp === 'string' ? otp : '';
    this.validate();
  };

  updateCallback: Function = () => { };

  private validate = () => {
    this.disabledButton = !(this.userId && this.siteId && this.otp);
    return this.disabledButton;
  };

  private changeOTP = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.otp = event.target.value as string;
    this.validate();
  };

  private updateUser = async () => {
    if (this.validate()) {
      return;
    }
    this.disabledButton = true;
    try {
      await this.users.update({
        otp: this.otp,
      });
    } catch (e) {
      this.validate();
    } finally {
      this.updateCallback();
    }
  };

  private generateOtp = () => {
    this.otp = getSimpleOTP();
    this.validate();
  };

  render() {
    const invalid = !this.userId || !this.siteId;
    const site = sites.find(({ value }) => value.toString() === this.siteId);
    return (
      <div>
        <Typography variant="h5">
          Generate new 1-time activation code
        </Typography>
        {(invalid) && (
          <Box mt={2}>
            <Alert severity="warning">User is not specified!</Alert>
          </Box>
        )}
        <Box my={2}>
          <FormControl fullWidth>
            <InputLabel>Site ID</InputLabel>
            <Input
              value={(site && site.name) || this.siteId}
              readOnly
            />
          </FormControl>
        </Box>
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>User ID</InputLabel>
                <Input
                  value={this.userId}
                  readOnly
                />
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <InputLabel>Research ID</InputLabel>
                <Input
                  readOnly
                  value={this.siteId && this.userId ? `${this.siteId}-${this.userId}` : ''}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel>Change 1-time activation code</InputLabel>
            <Input
              onChange={this.changeOTP}
              value={this.otp}
              disabled={invalid}
              endAdornment={(
                <InputAdornment position="end">
                  <Tooltip title="Generate new code" placement="top" arrow>
                    <IconButton disabled={invalid} size="small" edge="end" onClick={this.generateOtp}>
                      <Cached />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={tooltip}
                    placement="top"
                    onClose={() => { this.tooltip = false; }}
                    onOpen={() => { this.tooltip = true; }}
                    open={this.tooltip || this.tooltipFixed}
                    arrow
                  >
                    <IconButton size="small" edge="end" onClick={() => { this.tooltipFixed = !this.tooltipFixed; }}>
                      <Info />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )}
            />
          </FormControl>
        </Box>
        <Button
          fullWidth
          disabled={this.disabledButton}
          onClick={this.updateUser}
          size="large"
          variant="contained"
          color="primary"
        >
          Save
        </Button>
      </div>
    );
  }
}

export default UserUpdate;
