import * as React from 'react';
import { Switch } from 'react-router-dom';
import { RouteWrapper, Fallback } from './route';
import items from './items';
import { IRouteItem } from './interfaces';

const makeRoute = (list: IRouteItem[], parentPath = '') => {
  const routes: any[] = [];
  list.forEach(({
    component, path, id, items: children,
  }) => {
    const childPath = `${parentPath}${path}`.replace(/\/{2,}/g, '/').replace(/\/$/g, '');
    if (component) {
      routes.push(
        <RouteWrapper
          key={`${path}-${id}`}
          exact
          path={childPath}
          title={id}
          component={component}
        />,
      );
    }
    if (children && children.length) {
      routes.push(...makeRoute(children, childPath));
    }
  });
  return routes;
};

const Routes: React.SFC<any> = (): any => (
  <Switch>
    {makeRoute(items)}
    <Fallback />
  </Switch>
);
export default Routes;
