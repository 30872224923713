import moment from 'moment';
import { dateTimeFormat } from '../../constants';

export interface IRawPost {
  _id: string;
  text: string;
  file: string[];
  video: string[];
  photo: string[];
  status: string;
  createdAt: string;
  updatedAt: string;
  createdBy: {
    _id: string;
    username: string;
    avatarUrl: string | null;
    siteId: string;
    userId: string;
  },
  reports:
  {
    userId: string;
    reason: string;
    subject: string;
    message: string;
    updatedAt: string;
    createdAt: string;
    createdBy: {
      _id: string;
      username: string;
      avatarUrl: string | null;
      siteId: string;
      userId: string;
    }
  }[];
}

export interface IRawPostReport extends IRawPost {
  reports:
  {
    userId: string;
    reason: string;
    subject: string;
    message: string;
    updatedAt: string;
    createdAt: string;
    createdBy: {
      _id: string;
      username: string;
      avatarUrl: string | null;
      siteId: string;
      userId: string;
    }
  }[];
}

export interface IReport {
  reason: string;
  subject: string;
  message: string;
  updatedAt: string;
  createdAt: string;
  createdBy:
  {
    id: string;
    username: string;
    avatarUrl: string | null;
    siteId: string;
    userId: string;
  }
}

export interface IPost {
  id: string;
  text: string;
  file: string[];
  video: string[];
  photo: string[];
  status: string;
  createdAt: string;
  updatedAt: string;
  createdBy: {
    id: string;
    username: string;
    avatarUrl: string | null;
    siteId: string;
    userId: string;
  },
}

export interface IPostReports extends IPost {
  reports: IReport[];
}

export const media = ({
  _id: id, text, file, video, photo, status, createdAt, updatedAt,
  createdBy: {
    _id: createdById, username, avatarUrl, siteId, userId,
  },
}: IRawPost) => ({
  id,
  text,
  file,
  video,
  photo,
  status,
  createdAt: moment(createdAt).format(dateTimeFormat),
  updatedAt: moment(updatedAt).format(dateTimeFormat),
  createdBy: {
    id: createdById,
    username,
    avatarUrl,
    siteId,
    userId,
  },
});

export const report = ({
  _id, text, file, video, photo, status, createdAt, updatedAt, createdBy, reports,
}: IRawPostReport): IPostReports => ({
  ...media({
    _id, text, file, video, photo, status, createdAt, updatedAt, createdBy,
  } as IRawPost),
  reports: reports
    .sort((a, b) => (new Date(b.createdAt) > new Date(a.createdAt) ? 1 : -1))
    .map(({
      reason, subject, message, createdAt: rCreatedAt, updatedAt: rUpdatedAt,
      createdBy: {
        _id: id, username, avatarUrl, siteId, userId,
      },
    }) => ({
      reason,
      subject,
      message,
      createdAt: moment(rCreatedAt).format(dateTimeFormat),
      updatedAt: moment(rUpdatedAt).format(dateTimeFormat),
      createdBy:
      {
        id,
        username,
        avatarUrl,
        siteId,
        userId,
      },
    })),
});
