import React from 'react';
import { observer, inject } from 'mobx-react';
import { toJS } from 'mobx';
import {
  Button, Table, TableBody, TableCell, TableHead, TablePagination, TableRow,
  TableSortLabel, Typography, IconButton, Tooltip,
} from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { withStyles } from '@material-ui/styles';
// import {
//   FormControlLabel, Switch, Fab, Chip, Typography,
// } from '@material-ui/core';

import { IUser } from '../../../utils/dto/user';

import Create from '../create';
import Update from '../update';

const rowsPerPageOptions = [10, 25, 50, 100];

interface IHeadCells {
  id: keyof IUser;
  label: string;
  value?: (sourceValue: any, row: IUser) => any;
  unsortable?: boolean;
  padding?: 'checkbox' | 'none'
}

const headCells: IHeadCells[] = [
  {
    id: 'requestedResetPassword', label: '', unsortable: true, padding: 'checkbox',
  },
  { id: 'username', label: 'Username', padding: 'none' },
  { id: 'createdAt', label: 'Joined at' },
  { id: 'researchId', label: 'Research ID' },
  { id: 'lastActiveAt', label: 'Last Active' },
  { id: 'otp', label: '1-Time Activation Code' },
];

const styles = {};

@inject('store')
@observer
class UserList extends React.Component {
  users: any;

  modal: any;

  constructor(props: any) {
    super(props);
    this.users = props.store.user;
    this.modal = props.store.modal;

    this.users.setOrder('desc');
    this.users.setOrderBy(undefined);
    this.users.setPage(0);
    const [rowsPerPage] = rowsPerPageOptions;
    this.users.setRowsPerPage(rowsPerPage);
    this.users.setFilter(false);
    this.users.getList(true);
  }

  private getNewCodeModal = (user: any) => {
    this.users.setUser(user);
    this.modal.open(Update, { updateCallback: () => this.modal.close() });
  };

  private getMapped(data: any): IUser[] {
    const parsed = toJS(data);
    return parsed.map((user: IUser) => {
      const otp = user.otp || ((
        <>
          {'Activated '}
          {' '}
          <Button size="small" color="primary" variant="outlined" onClick={() => this.getNewCodeModal(user)}>Generate New Code</Button>
        </>
      ) as unknown) as null;
      const requestedResetPassword = user.requestedResetPassword
        ? (
          <Tooltip title="User requested reset password" placement="top" arrow>
            <IconButton size="small" edge="end" onClick={() => this.getNewCodeModal(user)}>
              <ErrorOutline />
            </IconButton>
          </Tooltip>
        )
        : null;
      const item = { ...user, otp, requestedResetPassword };
      return item;
    });
  }

  private startAddUser = () => {
    this.modal.open(Create, {});
  };

  private handleRequestSort = (property: keyof IUser) => {
    const { order, orderBy } = this.users;
    if (orderBy === property && order === 'asc') {
      this.users.setOrder('desc');
      this.users.setOrderBy(undefined);
      this.users.getList();
      return;
    }

    const isDesc = orderBy === property && order === 'desc';
    this.users.setOrder(isDesc ? 'asc' : 'desc');
    this.users.setOrderBy(property);
    this.users.getList();
  };

  private handleChangePage = (event: unknown, newPage: number) => {
    this.users.setPage(newPage);
    this.users.getList();
  };

  private handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.users.setRowsPerPage(+event.target.value);
    this.users.getList();
  };

  private handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.users.setFilter(event.target.checked);
    this.users.getList();
  };

  render() {
    const {
      order, orderBy, page, rowsPerPage, /* filter, */
    } = this.users;

    const paged = this.getMapped(this.users.list);

    return (
      <div>
        <Typography align="right">
          <Button size="large" variant="contained" color="primary" onClick={this.startAddUser}>Add New User</Button>
        </Typography>
        <hr />
        <Typography variant="h5">
          Users (
          {this.users.total || this.users.list.length}
          )
        </Typography>
        <div className="header">
          <TablePagination
            rowsPerPageOptions={
              [...rowsPerPageOptions.filter(i => i < this.users.total), this.users.total]
            }
            component="div"
            count={this.users.total}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': 'previous page' }}
            nextIconButtonProps={{ 'aria-label': 'next page' }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
        <Table stickyHeader aria-labelledby="tableTitle" size="medium" >
          <TableHead>
            <TableRow>
              {headCells.map(headCell => (
                <TableCell
                  key={headCell.id}
                  className={headCell.id}
                  sortDirection={orderBy && orderBy === headCell.id ? order : false}
                  padding={headCell.padding || 'default'}
                >
                  {
                    headCell.unsortable
                      ? headCell.label
                      : (
                        <TableSortLabel
                          active={orderBy === headCell.id}
                          direction={order}
                          onClick={() => this.handleRequestSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      )
                  }
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paged.map((row: any, index: number) => (
              <TableRow hover tabIndex={index} key={row.id} >
                {
                  headCells.map(headCell => (
                    <TableCell
                      key={`${row.id}-${headCell.id}`}
                      padding={headCell.padding || 'default'}
                    >
                      {headCell.value
                        ? headCell.value(row[headCell.id], row)
                        : row[headCell.id]}
                    </TableCell>
                  ))
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    );
  }
}
export default withStyles(styles)(UserList);
