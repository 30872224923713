import React from 'react';
import Drawer from '@material-ui/core/Drawer';
import { observer, inject } from 'mobx-react';
import items from '../../routes/items';
import MenuList from './list';


@inject('store')
@observer
class Navigator extends React.Component {
  menu: any;

  constructor(props: any) {
    super(props);
    this.menu = props.store.menu;
  }

  render() {
    const { state } = this.menu;
    return (
      <Drawer variant="persistent" open={state}>
        <MenuList id="mainmenu" items={items} />
      </Drawer>
    );
  }
}

export default Navigator;
