import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';

const homepageUrl = '/users';

@inject('store')
@observer
export class RouteWrapper extends React.Component<any> {
  menu: any;

  constructor(props: any) {
    super(props);
    this.menu = props.store.menu;
  }

  render() {
    const {
      exact, title, path, component: AppComponent,
    } = this.props;
    this.menu.setActive(path, title);
    return (
      <>
        <title>
          {'Admin panel - '}
          {title}
        </title>
        <Route
          exact={exact}
          path={path}
          render={props => <AppComponent {...props} />}
        />
      </>
    );
  }
}

@inject('store')
@observer
export class Fallback extends React.Component<any> {
  menu: any;

  constructor(props: any) {
    super(props);
    this.menu = props.store.menu;
  }

  render() {
    this.menu.setActive(homepageUrl);
    return (<Redirect to={homepageUrl} />);
  }
}
