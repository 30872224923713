import React from 'react';
// import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import IconButton from '@material-ui/core/IconButton';
// import MenuIcon from '@material-ui/icons/Menu';
import { observer, inject } from 'mobx-react';
import { withStyles } from '@material-ui/styles';

// const theme = useTheme();

const styles = {
  root: {
    flexGrow: 1,
  },
  // menuButton: {
  //   marginRight: theme.spacing(2),
  // },
  title: {
    flexGrow: 1,
  },
};


@inject('store')
@observer
class Header extends React.Component<any> {
  auth: any;

  menu: any;

  constructor(props: any) {
    super(props);
    this.auth = props.store.auth;
    this.menu = props.store.menu;
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            {/* <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={() => this.menu.toggleState()}
            >
              <MenuIcon />
            </IconButton> */}
            <Typography variant="h6" className={classes.title}>
              {this.menu.title}
            </Typography>
            {this.auth.username}
            {this.auth.email ? (` (${this.auth.email})`) : null}
            <Button color="inherit" onClick={() => this.auth.logout()}>Logout</Button>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withStyles(styles)(Header);
