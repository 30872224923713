import axios, { AxiosRequestConfig } from 'axios';
import querystring from 'querystring';

import { auth, progress, snackbar } from '../stores';

const prefix = 'admin';

const modes = {
  standalone: { sufix: '', port: 3001 },
  mobile: { sufix: '/api/imanage', port: 3000 },
};

const mode = modes.mobile;
// const mode = modes.standalone;

const DEFAULT_URL = window.location.origin.replace(`:${window.location.port}`, `:${mode.port}`) + mode.sufix;

const baseUrl: string = process.env.REACT_APP_BACKEND_URL || DEFAULT_URL;

const serialize = (obj: any) => querystring.stringify(obj);

const makeRequest = (
  method: 'get' | 'delete' | 'head' | 'options' | 'post' | 'put' | 'patch',
  uri: string,
  query: any = {},
  data?: any,
  dontUsePrefix?: boolean,
): Promise<any> => {
  let path = uri;
  if (!dontUsePrefix) {
    path = `${prefix}/${uri}`;
  }
  const options: AxiosRequestConfig = {
    url: `${baseUrl}/${path}?${serialize(query)}`,
    method,
    data,
    headers: {},
  };

  const { token } = auth;
  if (token) {
    options.headers.authorization = `Bearer ${token}`;
  }
  return axios(options);
};

export default async (
  method: 'get' | 'delete' | 'head' | 'options' | 'post' | 'put' | 'patch',
  url: string,
  body?: any,
  query: any = {},
  dontUsePrefix: boolean = false,
): Promise<any> => {
  try {
    progress.add();
    const response = await makeRequest(method, url, query, body, dontUsePrefix);
    return response?.data?.data?.content;
  } catch (error) {
    if (error.response && error.response.status === 401 && auth.token) {
      auth.logout();
    }
    snackbar.show((error.response?.data?.error?.message) || error.message, 'error');
    throw error;
  } finally {
    progress.complete();
  }
};
