import moment from 'moment';
import { dateFormat, dateTimeFormat } from '../../constants';

export interface IRawUser {
  _id: string;
  username: string | null;
  createdAt: string;
  siteId: string;
  userId: string;
  lastActiveAt: string | null;
  otp: string | null;
  requestedResetPassword?: boolean;
}

export interface IUser {
  id: string;
  username: string | null;
  createdAt: string;
  researchId: string;
  lastActiveAt: string | null;
  otp: string | Element | null;
  requestedResetPassword: boolean;
}

export const user = ({
  _id, username, createdAt, userId, siteId, lastActiveAt, otp, requestedResetPassword,
}: IRawUser) => ({
  id: _id,
  username: username || 'Pending',
  createdAt: moment(createdAt).format(dateFormat),
  userId,
  siteId,
  researchId: `${siteId}-${userId}`,
  lastActiveAt: lastActiveAt ? moment(lastActiveAt).format(dateTimeFormat) : 'Pending',
  otp,
  requestedResetPassword: requestedResetPassword || false,
});
