import React from 'react';
import { observer, inject } from 'mobx-react';
import {
  Typography, Card, CardHeader, Avatar, CardContent, CardActions, CardMedia,
  ListItemText, ListItemIcon, List, ListItem, ListItemProps, Divider, Grid, Chip,
} from '@material-ui/core';
import { AttachFile } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';

import { IPost, IPostReports } from '../../../utils/dto/post';

const styles: any = (theme: any) => ({
  card: {
    maxWidth: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(2),
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  video: {
    width: '100%',
  },
  avatar: {
    backgroundColor: red[500],
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(2),
    marginLeft: 'auto',
  },
});

const ListItemLink = (props: ListItemProps<'a', { button?: true }>) => <ListItem button component="a" {...props} />;

interface IPostProps {
  classes?: any;
  post: IPost | IPostReports;
  expanded?: boolean;
  actions?: any;
  children?: any;
}

@inject('store')
@observer
class Post extends React.Component<IPostProps> {
  render() {
    const {
      expanded = true, classes, post, children, actions,
    } = this.props;
    const photos = expanded
      ? post.photo
      : ((post.photo.length && [post.photo[0]]) || []);
    const videos = expanded
      ? post.video
      : ((post.video.length && [post.video[0]]) || []);
    const maxLength = 1000;
    const text = (expanded && post.text) || `${post.text.substr(0, maxLength).trim()}${post.text.length > maxLength ? '...' : ''}`;
    return (
      <Card className={classes.card} variant="outlined">
        <Grid container spacing={0}>
          <Grid item xs={9}>
            <CardHeader
              avatar={(
                <Avatar aria-label="recipe" className={classes.avatar} src={post.createdBy.avatarUrl || undefined}>
                  {post.createdBy.username.substr(0, 1).toUpperCase()}
                </Avatar>
              )}
              title={`Post created by: ${post.createdBy.username} (${post.createdBy.siteId}-${post.createdBy.userId})`}
              subheader={post.createdAt}
            />
          </Grid>
          <Grid item xs={3} className={classes.statusWrapper}>
            <Chip
              className={classes.status}
              size="small"
              color={post.status === 'BANNED' ? 'secondary' : ((post.status === 'ACTIVE' && 'primary') || 'default')}
              label={post.status}
            />
          </Grid>
        </Grid>
        {photos.map(img => (
          <CardMedia
            className={classes.media}
            image={img}
          />
        ))}
        {videos.map(video => (
          <div>
            <video autoPlay={false} controls className={classes.video} >
              <source src={video} type="video/mp4" />
            </video>
          </div>
        ))}
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="pre">
            {text}
          </Typography>
        </CardContent>

        {post.file.length > 0
          && (
            <List dense component="nav" aria-label="files">
              {post.file.map(file => (
                <ListItemLink button href={file} target="_blank" download>
                  <ListItemIcon>
                    <AttachFile />
                  </ListItemIcon>
                  <ListItemText primary={file.split('/').pop()?.replace(/(\?|#).*$/g, '')} />
                </ListItemLink>
              ))}
            </List>
          )
        }
        <Divider />
        {children}
        <CardActions disableSpacing>
          {actions}
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(Post);
