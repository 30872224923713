import { observable, action } from 'mobx';

class SnackbarStore {
  private queue: { message: string | null, severity: string | null }[] = [];

  @observable
  open = false;

  @observable
  message: string | null = null;

  @observable
  severity: string | null = null;

  @action('show snackbar')
  show = (message: string | null, severity: string | null = null) => {
    this.queue.push({ message, severity });

    if (this.open) {
      this.open = false;
    } else {
      this.processQueue();
    }
  };

  @action('process snackbars queue')
  processQueue = () => {
    if (this.queue.length > 0) {
      const { message = null, severity = null } = this.queue.shift() || {};
      this.message = message;
      this.severity = severity;
      this.open = true;
    }
  };

  @action('on close event')
  handleClose = (event: any, reason: string) => {
    if (reason === 'clickaway') {
      return;
    }
    this.open = false;
  };

  handleExited = () => {
    this.processQueue();
  };
}

export default new SnackbarStore();
