import React from 'react';
import clsx from 'clsx';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { IconButton, Button, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { ExpandMore } from '@material-ui/icons';

import Post from '../post';

import confirmModal from '../../utils/confirm-modal';

import { IPost } from '../../../utils/dto/post';

const styles: any = (theme: any) => ({
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
});


@inject('store')
@observer
class ReportPost extends React.Component<{ store?: any, classes?: any, post: IPost }> {
  posts: any;

  @observable
  expanded: boolean = false;

  constructor(props: any) {
    super(props);
    this.posts = props.store.post;
  }

  private toggleExpanded = () => {
    this.expanded = !this.expanded;
  };

  private ban = async () => {
    const { post } = this.props;
    this.posts.setReport(post);
    await this.posts.ban();
    await this.posts.getMediaList();
  };

  private unban = async () => {
    const { post } = this.props;
    this.posts.setReport(post);
    await this.posts.unban();
    await this.posts.getMediaList();
  };

  private getActionButton = () => {
    const { post } = this.props;
    const banned = post.status === 'BANNED';
    return (
      <>
        {post.status === 'PENDING' && (
          <Box ml={2}>
            <Button
              variant={banned ? 'outlined' : 'contained'}
              color="primary"
              size="small"
              onClick={() => confirmModal(this.unban)}
            >
              Publish
            </Button>
          </Box>
        )}
        <Box ml={2} >
          <Button
            variant={banned ? 'outlined' : 'contained'}
            color={banned ? 'primary' : 'secondary'}
            size="small"
            onClick={() => confirmModal(banned ? this.unban : this.ban)}
          >
            {banned ? 'Unban' : 'Ban'}
          </Button>
        </Box>
      </>
    );
  };

  render() {
    const { classes, post } = this.props;
    return (
      <Post
        post={post}
        expanded={this.expanded}
        actions={(
          <>
            {this.getActionButton()}
            <IconButton
              className={clsx(classes.expand, {
                [classes.expandOpen]: this.expanded,
              })}
              onClick={this.toggleExpanded}
              aria-expanded={this.expanded}
              aria-label="show more"
            >
              <ExpandMore />
            </IconButton>
          </>
        )}
      />
    );
  }
}

export default withStyles(styles)(ReportPost);
