import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import {
  Typography, FormControl, InputLabel, Select, MenuItem, Input, Button,
  InputAdornment, IconButton, Tooltip, Grid, Box, CircularProgress,
} from '@material-ui/core';
import { Info, Cached } from '@material-ui/icons';

import userApi from '../../../apis/user';

import { sites, otpTooltip as tooltip } from '../../../constants';
import { otp as getSimpleOTP } from '../../../utils';

@inject('store')
@observer
class UserCreate extends React.Component {
  users: any;

  @observable
  userId: string = '';

  @observable
  siteId: string = '';

  @observable
  otp: string = '';

  @observable
  tooltip: boolean = false;

  @observable
  tooltipFixed: boolean = false;

  @observable
  disabledButton: boolean = true;

  @observable
  disabledUserId: boolean = false;

  constructor(props: any) {
    super(props);
    this.users = props.store.user;
    this.resetForm();
  }

  private validate = () => {
    this.disabledButton = !(this.siteId && this.userId && this.otp);
    return this.disabledButton;
  };

  private changeSiteId = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.siteId = event.target.value as string;
    this.validate();
    this.setValidUserId();
  };

  private changeUserId = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.userId = event.target.value as string;
    setTimeout(() => {
      this.userId = this.userId.replace(/[^0-9]/g, '');
    }, 200);
    this.validate();
  };

  private changeOTP = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.otp = event.target.value as string;
    this.validate();
  };

  private createUser = async () => {
    if (this.validate()) {
      return;
    }
    this.disabledButton = true;
    try {
      await this.users.create({
        userId: this.userId,
        siteId: this.siteId,
        otp: this.otp,
      });
      this.resetForm();
    } catch (e) {
      this.validate();
    }
  };

  private setValidUserId = async () => {
    if (!this.siteId) {
      return;
    }
    this.userId = '';
    this.validate();
    this.disabledUserId = true;
    const { userId } = await userApi.getUserId(this.siteId);
    this.disabledUserId = false;
    this.userId = userId;
    this.validate();
  };

  private generateOtp = () => {
    this.otp = getSimpleOTP();
    this.validate();
  };

  private resetForm = () => {
    this.siteId = '';
    this.userId = '';
    this.generateOtp();
  };

  render() {
    return (
      <div>
        <Typography variant="h5">
          Add New User
        </Typography>
        <Box my={2}>
          <FormControl fullWidth>
            <InputLabel>Site ID</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.siteId}
              onChange={this.changeSiteId}
            >
              {sites.map(({ value, name }) => (
                <MenuItem value={value} key={value + name}>
                  {value}
                  {' '}
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>User ID</InputLabel>
                <Input
                  onChange={this.changeUserId}
                  value={this.userId}
                  disabled={!this.siteId || this.disabledUserId}
                  endAdornment={(
                    <InputAdornment position="end">
                      {this.disabledUserId
                        ? (
                          <IconButton size="small" edge="end" disabled>
                            <CircularProgress size={24} />
                          </IconButton>
                        )
                        : (
                          <Tooltip title="Refresh latest valid User ID from database" placement="top" arrow>
                            <IconButton size="small" edge="end" disabled={!this.siteId} onClick={this.setValidUserId}>
                              <Cached />
                            </IconButton>
                          </Tooltip>
                        )
                      }
                      <Tooltip title="Numeric unique User ID" placement="top" arrow >
                        <IconButton size="small" edge="end">
                          <Info />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth>
                <InputLabel>Research ID</InputLabel>
                <Input
                  disabled
                  value={this.siteId && this.userId ? `${this.siteId}-${this.userId}` : ''}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel>Create 1-time activation code</InputLabel>
            <Input
              onChange={this.changeOTP}
              value={this.otp}
              endAdornment={(
                <InputAdornment position="end">
                  <Tooltip title="Generate new code" placement="top" arrow>
                    <IconButton size="small" edge="end" onClick={this.generateOtp}>
                      <Cached />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={tooltip}
                    placement="top"
                    onClose={() => { this.tooltip = false; }}
                    onOpen={() => { this.tooltip = true; }}
                    open={this.tooltip || this.tooltipFixed}
                    arrow
                  >
                    <IconButton size="small" edge="end" onClick={() => { this.tooltipFixed = !this.tooltipFixed; }}>
                      <Info />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )}
            />
          </FormControl>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Button
              fullWidth
              disabled={this.disabledButton}
              onClick={this.createUser}
              size="large"
              variant="contained"
              color="primary"
            >
              Add User
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              disabled={this.disabledButton}
              onClick={this.resetForm}
              size="large"
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default UserCreate;
