import { observable, action } from 'mobx';

import * as api from '../../apis';
import snackbarStore from '../snackbar';

import {
  IPostReports, IRawPostReport, report as mapReport, media as mapMedia,
} from '../../utils/dto/post';

export type Order = 'asc' | 'desc';

const defOrderBy = 'createdAt';

class ReportsStore {
  @observable
  list: IPostReports[] = [];

  @observable
  report: IPostReports | undefined;

  @observable
  total = 0;

  @observable
  order: Order = 'desc';

  @observable
  orderBy: keyof IPostReports | undefined = defOrderBy;

  @observable
  page: number = 0;

  @observable
  rowsPerPage: number = 10;

  @observable
  status: string | undefined;

  setOrder(order: Order) {
    this.order = order;
  }

  setOrderBy(orderBy: keyof IPostReports | undefined) {
    this.orderBy = orderBy || defOrderBy;
  }

  setPage(page: number) {
    this.page = page;
  }

  setRowsPerPage(rowsPerPage: number) {
    this.rowsPerPage = rowsPerPage;
    this.page = 0;
  }

  setFilter() {
    this.page = 0;
  }

  setStatus(status?: string | undefined) {
    this.status = status;
    this.page = 0;
  }

  @action('set report')
  setReport(report: any) {
    this.report = report;
  }

  async getReportList(reload: boolean = false) {
    if (reload) {
      this.list = [];
    }
    const options = {
      status: this.status,
      limit: this.rowsPerPage,
      // offset: this.page * this.rowsPerPage,
      offset: this.page + 1,
      orderBy: this.orderBy,
      order: this.order,
    };
    const { records, total } = await api.post.reports(options);
    this.list = records.map((report: IRawPostReport) => mapReport(report));
    this.total = total;
  }

  async getMediaList(reload: boolean = false) {
    if (reload) {
      this.list = [];
    }
    const options = {
      status: this.status,
      limit: this.rowsPerPage,
      // offset: this.page * this.rowsPerPage,
      offset: this.page + 1,
      orderBy: this.orderBy,
      order: this.order,
    };
    const { records, total } = await api.post.media(options);
    this.list = records.map((report: IRawPostReport) => mapMedia(report));
    this.total = total;
  }

  async ban() {
    if (!this.report) {
      snackbarStore.show('Post is not selected', 'error');
      return;
    }
    await api.post.ban(this.report.id);
    snackbarStore.show('Post has been banned successfully');
  }

  async unban() {
    if (!this.report) {
      snackbarStore.show('Post is not selected', 'error');
      return;
    }
    await api.post.unban(this.report.id);
    snackbarStore.show('Post has been unbanned successfully');
  }
}
export default new ReportsStore();
