
require('dotenv').config();
/* eslint import/first: 0 */
import React from 'react';
import * as ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { Provider } from 'mobx-react';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import history from './utils/history';
import routing from './utils/routing';
import * as store from './stores';

import App from './components/app';
import Modal from './components/utils/modal';
import Progressbar from './components/utils/progressbar';
import Snackbar from './components/utils/snackbar';

import './styles/index.scss';

const theme = createMuiTheme({});

ReactDOM.render(
  (
    <MuiThemeProvider theme={theme}>
      <Typography variant="body1" component="div">
        <Provider routing={routing} store={store}>
          <Router history={history}>
            <Progressbar />
            <App />
            <Modal />
            <Snackbar />
          </Router>
        </Provider>
      </Typography>
    </MuiThemeProvider>
  ),
  document.getElementById('root') as HTMLElement,
);
