import { createBrowserHistory } from 'history';
import { syncHistoryWithStore } from 'mobx-react-router';

import routing from '../routing';

const browserHistory = createBrowserHistory();

const history = syncHistoryWithStore(browserHistory, routing);

export default history;
