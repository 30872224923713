import React from 'react';
import { inject, observer } from 'mobx-react';
import { Box, Paper } from '@material-ui/core';

import Routes from '../../routes';

import Header from '../header';
import Login from '../login';
import Menu from '../menu';

@inject('store')
@observer
class App extends React.Component<any> {
  auth: any;

  constructor(props: any) {
    super(props);
    this.auth = props.store.auth;
  }

  render() {
    if (!this.auth.token) {
      return (<Login />);
    }
    return (
      <>
        <div id="content">
          <Header />
          <main>
            <Paper>
              <Box p={2}>
                <Routes />
              </Box>
            </Paper>
          </main>
        </div>
        <nav>
          <Menu />
        </nav>
      </>
    );
  }
}

export default App;
