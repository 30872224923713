import { observable, action } from 'mobx';

import * as api from '../../apis';
import snackbarStore from '../snackbar';

import { IAdmin, IRawAdmin, admin as mapAdmin } from '../../utils/dto/admin';

export type Order = 'asc' | 'desc';

const defOrderBy = 'createdAt';

class AdminsStore {
  @observable
  list: IAdmin[] = [];

  @observable
  admin: IAdmin | undefined;

  @observable
  total = 0;

  @observable
  order: Order = 'desc';

  @observable
  orderBy: keyof IAdmin | undefined = defOrderBy;

  @observable
  page: number = 0;

  @observable
  rowsPerPage: number = 10;

  @observable
  filter: boolean = false;

  setOrder(order: Order) {
    this.order = order;
  }

  setOrderBy(orderBy: keyof IAdmin | undefined) {
    this.orderBy = orderBy || defOrderBy;
  }

  setPage(page: number) {
    this.page = page;
  }

  setRowsPerPage(rowsPerPage: number) {
    this.rowsPerPage = rowsPerPage;
    this.page = 0;
  }

  setFilter(filter: boolean) {
    this.filter = filter;
    this.page = 0;
  }

  @action('set admin')
  setAdmin(admin: any) {
    this.admin = admin;
  }

  async getList(reload: boolean = false) {
    if (reload) {
      this.list = [];
    }
    const options = {
      limit: this.rowsPerPage,
      // offset: this.page * this.rowsPerPage,
      offset: this.page + 1,
      orderBy: this.orderBy,
      order: this.order,
    };
    const { records, total } = await api.admin.list(options);
    this.list = records.map((admin: IRawAdmin) => mapAdmin(admin));
    this.total = total;
  }

  async getById(id: string) {
    const { admin } = await api.admin.get(id);
    this.admin = mapAdmin(admin);
  }

  async update(dataToUpdate: any) {
    if (!this.admin) {
      snackbarStore.show('Admin is not selected', 'error');
      return;
    }
    await api.admin.update(
      {
        ...dataToUpdate,
        id: this.admin.id,
      },
    );
    snackbarStore.show('Admin has been updated successfully');
    this.getList();
  }

  async create(dataToCreate: any) {
    await api.admin.create(dataToCreate);
    snackbarStore.show('Admin has been created successfully');
    this.getList();
  }
}
export default new AdminsStore();
