import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable, toJS } from 'mobx';
import {
  Typography, FormControl, InputLabel, Select, MenuItem, Input, Button,
  InputAdornment, IconButton, Tooltip, Box,
} from '@material-ui/core';
import { Info, VisibilityOff, Visibility } from '@material-ui/icons';

import { statuses } from '../../../constants';

@inject('store')
@inject('routing')
@observer
class AdminUpdate extends React.Component<any> {
  admins: any;

  @observable
  username: string = '';

  @observable
  email: string = '';

  @observable
  status: string = '';

  @observable
  password: string = '';

  @observable
  tooltip: boolean = false;

  @observable
  tooltipFixed: boolean = false;

  @observable
  disabledButton: boolean = true;

  @observable
  passwordVisibility: boolean = false;

  constructor(props: any) {
    super(props);
    const { updateCallback } = props;
    if (updateCallback) {
      this.updateCallback = updateCallback;
    }
    this.admins = props.store.admin;
    this.loadAdmin();
  }

  private loadAdmin = async () => {
    const { match = {} } = this.props;
    if (match && match.params && match.params.adminId) {
      await this.admins.getById(match.params.adminId);
    }
    const { status = null, email = null, username = null } = toJS(this.admins.admin) || {};
    this.status = status;
    this.email = email;
    this.username = username;
    this.validate();
  };

  updateCallback: Function = () => { };

  private validate = () => {
    this.disabledButton = !(this.email && this.username && this.status);
    return this.disabledButton;
  };

  private changeEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.email = event.target.value as string;
    this.validate();
  };

  private changeUsername = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.username = event.target.value as string;
    this.validate();
  };

  private changePassword = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.password = event.target.value as string;
    this.validate();
  };

  private changeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.status = event.target.value as string;
    this.validate();
  };

  private updateAdmin = async () => {
    if (this.validate()) {
      return;
    }
    this.disabledButton = true;
    try {
      const body: { [key: string]: string } = {
        email: this.email,
        username: this.username,
        status: this.status,
      };
      if (this.password) {
        body.password = this.password;
      }
      await this.admins.update(body);
    } catch (e) {
      this.validate();
    } finally {
      this.updateCallback();
    }
  };

  render() {
    return (
      <div>
        <Typography variant="h5">
          Add New Admin
        </Typography>
        <Box my={2}>
          <FormControl fullWidth>
            <InputLabel>Email</InputLabel>
            <Input
              onChange={this.changeEmail}
              value={this.email}
              endAdornment={(
                <InputAdornment position="end">
                  <Tooltip title="Unique admin email, can be used for log in" placement="top" arrow>
                    <IconButton size="small" edge="end">
                      <Info />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )}
            />
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel>Username</InputLabel>
            <Input
              onChange={this.changeUsername}
              value={this.username}
              endAdornment={(
                <InputAdornment position="end">
                  <Tooltip title="Unique admin login" placement="top" arrow>
                    <IconButton size="small" edge="end">
                      <Info />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )}
            />
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel>Password</InputLabel>
            <Input
              type={this.passwordVisibility ? 'text' : 'password'}
              onChange={this.changePassword}
              value={this.password}
              endAdornment={(
                <>
                  <InputAdornment position="end">
                    <Tooltip title="Set only when you want to change password" placement="top" arrow>
                      <IconButton size="small" edge="end">
                        <Info />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                  <InputAdornment position="end">
                    <Tooltip title="Show password" placement="top" arrow>
                      <IconButton
                        size="small"
                        edge="end"
                        onClick={() => { this.passwordVisibility = !this.passwordVisibility; }}
                      >
                        {this.passwordVisibility ? (<VisibilityOff />) : (<Visibility />)}
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                </>
              )}
            />
          </FormControl>
        </Box>
        <Box my={2}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={this.status}
              onChange={this.changeStatus}
            >
              {statuses.map((status: string) => (
                <MenuItem value={status} key={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Button
          fullWidth
          disabled={this.disabledButton}
          onClick={this.updateAdmin}
          size="large"
          variant="contained"
          color="primary"
        >
          Update Admin
        </Button>
      </div>
    );
  }
}

export default AdminUpdate;
