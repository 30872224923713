const voc = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

function getSymbol() {
  return voc[Math.floor(Math.random() * voc.length)];
}

export default function otp() {
  let str = '';
  while (str.length < 5) {
    str += getSymbol();
  }
  return str;
}
