import React from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import {
  Typography, FormControl, InputLabel, Select, MenuItem, Input, Button,
  InputAdornment, IconButton, Tooltip, Grid, Box,
} from '@material-ui/core';
import { Info, Visibility, VisibilityOff } from '@material-ui/icons';

import { statuses } from '../../../constants';

const [defStatus] = statuses;

@inject('store')
@observer
class AdminCreate extends React.Component {
  admins: any;

  @observable
  username: string = '';

  @observable
  email: string = '';

  @observable
  password: string = '';

  @observable
  status: string = defStatus;

  @observable
  disabledButton: boolean = true;

  @observable
  passwordVisibility: boolean = false;

  constructor(props: any) {
    super(props);
    this.admins = props.store.admin;
    this.resetForm();
  }

  private validate = () => {
    this.disabledButton = !(this.email && this.username && this.password && this.status);
    return this.disabledButton;
  };

  private changeEmail = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.email = event.target.value as string;
    this.validate();
  };

  private changeUsername = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.username = event.target.value as string;
    this.validate();
  };

  private changePassword = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.password = event.target.value as string;
    this.validate();
  };

  private changeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.status = event.target.value as string;
    this.validate();
  };

  private createAdmin = async () => {
    if (this.validate()) {
      return;
    }
    this.disabledButton = true;
    try {
      await this.admins.create({
        username: this.username,
        email: this.email,
        password: this.password,
        status: this.status,
      });
      this.resetForm();
    } catch (e) {
      this.validate();
    }
  };

  private resetForm = () => {
    this.email = '';
    this.username = '';
    this.password = '';
    this.status = defStatus;
  };

  render() {
    return (
      <div>
        <Typography variant="h5">
          Add New Admin
        </Typography>
        <Box my={2}>
          <FormControl fullWidth>
            <InputLabel>Email</InputLabel>
            <Input
              onChange={this.changeEmail}
              value={this.email}
              endAdornment={(
                <InputAdornment position="end">
                  <Tooltip title="Unique admin email, can be used for log in" placement="top" arrow>
                    <IconButton size="small" edge="end">
                      <Info />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )}
            />
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel>Username</InputLabel>
            <Input
              onChange={this.changeUsername}
              value={this.username}
              endAdornment={(
                <InputAdornment position="end">
                  <Tooltip title="Unique admin login" placement="top" arrow>
                    <IconButton size="small" edge="end">
                      <Info />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )}
            />
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth>
            <InputLabel>Password</InputLabel>
            <Input
              type={this.passwordVisibility ? 'text' : 'password'}
              onChange={this.changePassword}
              value={this.password}
              endAdornment={(
                <InputAdornment position="end">
                  <Tooltip title="Show password" placement="top" arrow>
                    <IconButton
                      size="small"
                      edge="end"
                      onClick={() => { this.passwordVisibility = !this.passwordVisibility; }}
                    >
                      {this.passwordVisibility ? (<VisibilityOff />) : (<Visibility />)}
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              )}
            />
          </FormControl>
        </Box>
        <Box my={2}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={this.status}
              onChange={this.changeStatus}
            >
              {statuses.map((status: string) => (
                <MenuItem value={status} key={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Button
              fullWidth
              disabled={this.disabledButton}
              onClick={this.createAdmin}
              size="large"
              variant="contained"
              color="primary"
            >
              Add Admin
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              disabled={this.disabledButton}
              onClick={this.resetForm}
              size="large"
            >
              Clear
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default AdminCreate;
