import { observable, action } from 'mobx';

class ProgressStore {
  private queue: any[] = [];

  private finished: any[] = [];

  private timer: any;

  @observable
  completed: number | null = null;

  private calc() {
    clearInterval(this.timer);
    this.completed = (this.finished.length / this.queue.length) * 100;
    const next = ((this.finished.length + 1) / this.queue.length) * 100;
    this.timer = setInterval(() => {
      if (this.completed !== null) {
        this.completed += Math.max(Math.random() * ((next * 0.9) - this.completed), 0);
      }
    }, 100);

    if (this.finished.length === this.queue.length) {
      this.finished = [];
      this.queue = [];
      setTimeout(() => {
        this.completed = null;
      }, 500);
    }
  }

  @action('add action')
  add(count: number = 1) {
    this.queue.push(...new Array(count));
    this.calc();
  }

  @action('finish action')
  complete() {
    this.finished.push(null);
    this.calc();
  }
}

export default new ProgressStore();
