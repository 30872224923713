import React from 'react';
import { observer, inject } from 'mobx-react';
import {
  Avatar, Table, TableBody, TableRow, TableCell,
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/styles';

import { IReport } from '../../../utils/dto/post';

const styles: any = (theme: any) => ({
  createdBy: {
    display: 'flex',
    lineHeight: '2em',
  },
  avatar: {
    height: '2em',
    width: '2em',
    fontSize: '1em',
    marginRight: theme.spacing(1),
    backgroundColor: red[300],
  },
});


@inject('store')
@observer
class ReportItem extends React.Component<{ store?: any, classes?: any, report: IReport }> {
  reports: any;

  constructor(props: any) {
    super(props);
    this.reports = props.store.post;
  }

  render() {
    const { classes, report } = this.props;
    return (
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell>
              Reported by:
            </TableCell>
            <TableCell>
              <div className={classes.createdBy}>
                <Avatar
                  className={classes.avatar}
                  src={report.createdBy.avatarUrl || undefined}
                >
                  {report.createdBy.username.substr(0, 1).toUpperCase()}
                </Avatar>
                {report.createdBy.username}
                {` (${report.createdBy.siteId}-${report.createdBy.userId})`}
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Reported at:
            </TableCell>
            <TableCell>
              {report.createdAt}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Reason:
            </TableCell>
            <TableCell>
              {report.reason}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Subject:
            </TableCell>
            <TableCell>
              {report.subject}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Message:
            </TableCell>
            <TableCell>
              {report.message}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

export default withStyles(styles)(ReportItem);
