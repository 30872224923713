// import React from 'react';
// import HomeIcon from '@material-ui/icons/Home';

import { IRouteItem } from './interfaces';

import admins from './admins';
import posts from './posts';
import users from './users';

const items: IRouteItem[] = [
  // { id: 'Home', icon: <HomeIcon />, path: '/' },
  ...users,
  ...admins,
  ...posts,
];

export default items;
