import React from 'react';
import {
  Typography, Grid, Button, Box,
} from '@material-ui/core';

import { modal } from '../../../stores';

class ConfirmModal extends React.Component<any> {
  private positiveHandler = () => {
    const { positive } = this.props;
    if (positive) {
      positive();
    }
    modal.close();
  };

  private negativeHandler = () => {
    const { negative } = this.props;
    if (negative) {
      negative();
    }
    modal.close();
  };

  render() {
    return (
      <div>
        <Box mt={1} mb={2}>
          <Typography variant="h6">
            Are you sure?
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={this.positiveHandler}
              size="small"
              variant="contained"
              color="primary"
            >
              Yes
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              onClick={this.negativeHandler}
              variant="outlined"
              size="small"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default (positive?: () => void, negative?: () => void) => {
  modal.open(ConfirmModal, { positive, negative });
};
