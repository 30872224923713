import { observable, action } from 'mobx';

import * as api from '../../apis';
import snackbarStore from '../snackbar';

import { IUser, IRawUser, user as mapUser } from '../../utils/dto/user';

export type Order = 'asc' | 'desc';

const defOrderBy = 'createdAt';

class UsersStore {
  @observable
  list: IUser[] = [];

  @observable
  user: IUser | undefined;

  @observable
  total = 0;

  @observable
  order: Order = 'desc';

  @observable
  orderBy: keyof IUser | undefined = defOrderBy;

  @observable
  page: number = 0;

  @observable
  rowsPerPage: number = 10;

  @observable
  filter: boolean = false;

  setOrder(order: Order) {
    this.order = order;
  }

  setOrderBy(orderBy: keyof IUser | undefined) {
    this.orderBy = orderBy || defOrderBy;
  }

  setPage(page: number) {
    this.page = page;
  }

  setRowsPerPage(rowsPerPage: number) {
    this.rowsPerPage = rowsPerPage;
    this.page = 0;
  }

  setFilter(filter: boolean) {
    this.filter = filter;
    this.page = 0;
  }

  @action('set user')
  setUser(user: any) {
    this.user = user;
  }

  async getList(reload: boolean = false) {
    if (reload) {
      this.list = [];
    }
    const options = {
      limit: this.rowsPerPage,
      // offset: this.page * this.rowsPerPage,
      offset: this.page + 1,
      orderBy: this.orderBy === 'researchId' ? ['siteId', 'userId'] : this.orderBy,
      order: this.order,
    };
    const { records, total } = await api.user.list(options);
    this.list = records.map((user: IRawUser) => mapUser(user));
    this.total = total;
  }

  async getById(id: string) {
    const { user } = await api.user.get(id);
    this.user = mapUser(user);
  }

  async update(dataToUpdate: any) {
    if (!this.user) {
      snackbarStore.show('User is not selected', 'error');
      return;
    }
    await api.user.update(
      {
        ...dataToUpdate,
        id: this.user.id,
      },
    );
    snackbarStore.show('User has been updated successfully');
    this.getList();
  }

  async create(dataToCreate: any) {
    await api.user.create(dataToCreate);
    snackbarStore.show('User has been created successfully');
    this.getList();
  }
}
export default new UsersStore();
