import React from 'react';
import { People, PersonAdd } from '@material-ui/icons';
import UserList from '../../components/user/list';
import UserCreate from '../../components/user/create';
import UserUpdate from '../../components/user/update';

import { IRouteItem } from '../interfaces';

const items: IRouteItem[] = [
  {
    id: 'Users',
    icon: <People />,
    path: '/users',
    items: [
      {
        id: 'Users List', icon: <People />, path: '/', component: UserList,
      },
      {
        id: 'Create User', icon: <PersonAdd />, path: '/create', component: UserCreate,
      },
      {
        id: 'Update User', icon: <People />, path: '/update/:userId', component: UserUpdate, menu: false,
      },
    ],
  },
];

export default items;
