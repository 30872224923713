import React from 'react';
import { SupervisorAccount, PlusOne } from '@material-ui/icons';
import AdminList from '../../components/admin/list';
import AdminCreate from '../../components/admin/create';
import AdminUpdate from '../../components/admin/update';

import { IRouteItem } from '../interfaces';

const items: IRouteItem[] = [
  {
    id: 'Admins',
    icon: <SupervisorAccount />,
    path: '/admins',
    items: [
      {
        id: 'Admins List',
        icon: <SupervisorAccount />,
        path: '/',
        component: AdminList,
      },
      {
        id: 'Create Admin', icon: <PlusOne />, path: '/create', component: AdminCreate,
      },
      {
        id: 'Update Admin', icon: <SupervisorAccount />, path: '/update/:adminId', component: AdminUpdate, menu: false,
      },
    ],
  },
];

export default items;
