import { observable, action } from 'mobx';

class ModalStore {
  @observable
  state: boolean = true;

  @observable
  component: any = null;

  @observable
  props: any = null;

  @action('open modal')
  open(component: any, props?: any) {
    this.state = true;
    this.component = component;
    this.props = (component && props) || null;
  }

  @action('close modal')
  close() {
    this.state = false;
    this.component = null;
    this.props = null;
  }
}

export default new ModalStore();
